import { useState, useEffect } from 'react';
import Breakpoints from 'lib/Breakpoints';

const queryMap = {
  s: Breakpoints.getSmallMQL,
  m: Breakpoints.getMediumMQL,
  l: Breakpoints.getLargeMQL,
  xl: Breakpoints.getXLargeMQL,
  xxl: Breakpoints.getXXLargeMQL,
  xxxl: Breakpoints.getXXXLargeMQL,
};

export const errorMessage = 'useMediaQuery requires a valid query. Allowed values s, m, l, xl, xxl, xxxl';

export function useMediaQuery(query) {
  const mediaQuery = queryMap[query];
  if (!mediaQuery) throw new Error(errorMessage);
  const [matches, setMatches] = useState(mediaQuery().matches);

  useEffect(() => {
    const listener = (e) => setMatches(e.matches);
    mediaQuery().addListener(listener);
    return () => mediaQuery().removeListener(listener);
  }, [query]);

  return matches;
}
