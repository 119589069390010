import { useContext } from 'react';
import { VerticalContext } from 'lib/ContextTypes';
import {
  BETTER,
  ENTRETENIMIENTO,
  EONLINE,
  GLOBALCITIZEN,
  MSNBC,
  NEWS,
  NOTICIAS,
  SELECT_CNBC,
  SELECT_NEWS,
  SHOWS,
  TELEMUNDO,
  THINK,
  TODAY,
} from 'lib/constants';

export function useVertical() {
  const vertical = useContext(VerticalContext);

  const isBetter = vertical === BETTER;
  const isEntretenimiento = vertical === ENTRETENIMIENTO;
  const isEonline = vertical === EONLINE;
  const isGlobalcitizen = vertical === GLOBALCITIZEN;
  const isMsnbc = vertical === MSNBC;
  const isNews = vertical === NEWS;
  const isNoticias = vertical === NOTICIAS;
  const isSelectCnbc = vertical === SELECT_CNBC;
  const isSelectNews = vertical === SELECT_NEWS;
  const isShows = vertical === SHOWS;
  const isTelemundo = vertical === TELEMUNDO;
  const isThink = vertical === THINK;
  const isToday = vertical === TODAY;

  return {
    vertical,
    isBetter,
    isEntretenimiento,
    isEonline,
    isGlobalcitizen,
    isMsnbc,
    isNews,
    isNoticias,
    isSelectCnbc,
    isSelectNews,
    isShows,
    isTelemundo,
    isThink,
    isToday,
  };
}
