import { useEffect } from 'react';
import { stub as $t } from '@nbcnews/analytics-framework';

/**
* Register a tracking event and return a function to track it
* @param {string} eventName - The name of the event to track
* @param {object} options - Options to pass to the analytics framework
* @returns track - A function to track the event with params
*/
export function useTracking(eventName, options = { allowDuplicate: true }) {
  const registerTrackingEvent = () => $t('register', eventName, options);
  const track = (params = {}) => $t('track', eventName, params);
  useEffect(registerTrackingEvent, [$t]);
  return track;
}
