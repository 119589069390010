import { useCallback, useEffect, useRef } from 'react';

/**
 * Copied from {@link https://usehooks-ts.com/react-hook/use-is-mounted usehooks-ts}. Returns a
 * function which returns whether or not the component is currently mounted or has unmounted.
 * @returns {() => boolean}
 */
function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return useCallback(() => isMounted.current, []);
}

export { useIsMounted };
