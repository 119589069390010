import { useEffect, useRef } from 'react';

/**
 * Custom effect hook to only fire after a component has initially rendered.
 *
 * @param {function} effectCallback Use effect callback to only be fired when dependencies change
 * @param {any[]} dependencies Array of dependencies
 */
export const useEffectAfterInitalRender = (effectCallback, dependencies) => {
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    effectCallback();
  }, dependencies);
};
