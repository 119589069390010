import { useEffect, useCallback } from 'react';

/**
 * UseEffect wrapped in a debounce. Used to prevent effect callbacks from being called more than
 * once in a given delay window.
 *
 * @param {function} effect - Effect callback to be memoized
 * @param {number} delay - Millisecond delay to wrap the debounce
 * @param {any[]} deps - Array of dependencies to be passed to the effect
 */
export const useDebouncedEffect = (effect, delay, deps) => {
  const callback = useCallback(effect, deps);

  useEffect(() => {
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [callback, delay]);
};
